import { useContext,useEffect } from 'react'
import { useNavigate,Navigate,Outlet,Link } from "react-router-dom"
import axios from '../utils/axios'
import { toast } from "react-toastify"
import { Context } from '../context/Context'
import Sidebar from '../pages/Partials/Sidebar'

const Panel = () => {

    document.body.classList.remove('login-img')

    if(localStorage.getItem('theme') == 'dark')
        document.body.classList.add('dark-mode')

    const navigate = useNavigate()
    const context = useContext(Context)

    useEffect(() => {
        if(localStorage.getItem('token'))
            axios.get('init/profile').then(({data}) => {
                if(data.status)
                    context.setUser(data.result)
            })
    },[])

    const setDarkModel = darkMode => {
        if(darkMode)
            document.body.classList.add('dark-mode')
        else
            document.body.classList.remove('dark-mode')
        localStorage.setItem('theme',darkMode ? 'dark' : 'light')
    }

    const logout = e => {
        e.preventDefault()
        axios.post('auth/logout').then(() => {
            toast.success('خروج از سیستم با موفقیت انجام شد')
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            navigate('/login')
        })
    }
    
    return (
        <div className="page">
            <div className="page-main">
                <div className="app-header header sticky">
                    <div className="container-fluid main-container">
                        <div className="d-flex">
                            <a aria-label="Hide Sidebar" className="app-sidebar__toggle" data-bs-toggle="sidebar" href="#"></a>
                            <a className="logo-horizontal " href="/">
                                <img src="/assets/images/brand/logo.png" className="header-brand-img desktop-logo" alt="logo" />
                                <img src="/assets/images/brand/logo-3.png" className="header-brand-img light-logo1" alt="logo" />
                            </a>
                            <div className="d-flex order-lg-2 ms-auto header-right-icons">
                                <button className="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon fe fe-more-vertical"></span>
                                </button>
                                <div className="navbar navbar-collapse responsive-navbar p-0">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                        <div className="d-flex order-lg-2">
                                            <div className="d-flex country">
                                                <a className="nav-link icon theme-layout nav-link-bg">
                                                    <span className="dark-layout" onClick={() => setDarkModel(true)}><i className="fe fe-moon" style={{position: 'relative',top: 3}}></i></span>
                                                    <span className="light-layout" onClick={() => setDarkModel(false)}><i className="fe fe-sun" style={{position: 'relative',top: 3}}></i></span>
                                                </a>
                                            </div>
                                            <div className="dropdown d-flex">
                                                <a className="nav-link icon full-screen-link nav-link-bg">
                                                    <i className="fe fe-minimize fullscreen-button"></i>
                                                </a>
                                            </div>
                                            <div className="dropdown  d-flex notifications">
                                                <a className="nav-link icon" data-bs-toggle="dropdown"><i
                                                        className="fe fe-bell"></i><span className=" pulse"></span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <div className="drop-heading border-bottom">
                                                        <div className="d-flex">
                                                            <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">اعلانات
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="notifications-menu">
                                                        <p className="pt-4 pb-1 px-3">اطلاعیه ای در سیستم وجود ندارد</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdown d-flex profile-1">
                                                <a href="#" data-bs-toggle="dropdown" className="nav-link leading-none d-flex">
                                                    <img src="/assets/images/default.png" alt="profile-user" className="avatar profile-user brround cover-image" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <div className="drop-heading">
                                                        <div className="text-center">
                                                            <h5 className="text-dark mb-0 fs-14 fw-semibold">{context.user.name}</h5>
                                                            <small className="text-muted">مدیریت</small>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-divider m-0"></div>
                                                    <Link className="dropdown-item" to={`/users/update/${context.user.id}`}>
                                                        <i className="dropdown-icon fe fe-user"></i> پروفایل
                                                    </Link>
                                                    <a className="dropdown-item" href="#" onClick={e => logout(e)}>
                                                        <i className="dropdown-icon fe fe-alert-circle"></i> خروج از سیستم
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Sidebar />
                <div className="main-content app-content">
                    <div className="side-app">
                        <div className="main-container container-fluid">
                            {localStorage.getItem('token') && localStorage.getItem('token') != null ? <Outlet /> : <Navigate to="/login" />}
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer bg-white">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-md-12 col-sm-12 text-center">
                        <p className="mb-0">توسعه یافته توسط <a href="https://mrwebyar.com" target="_blank">مستر وب یار</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Panel