import { NavLink } from 'react-router-dom'

const Sidebar = () => {
    return (
        <div className="sticky">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
            <div className="app-sidebar">
                <div className="side-header">
                    <NavLink className="header-brand1" to="/">
                        <img src="/assets/images/brand/logo.png" className="header-brand-img desktop-logo" alt="logo" />
                        <img src="/assets/images/brand/logo-1.png" className="header-brand-img toggle-logo" alt="logo" />
                        <img src="/assets/images/brand/logo-2.png" className="header-brand-img light-logo" alt="logo" />
                        <img src="/assets/images/brand/logo-3.png" className="header-brand-img light-logo1" alt="logo" />
                    </NavLink>
                </div>
                <div className="main-sidemenu">
                    <div className="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"/></svg></div>
                    <ul className="side-menu">
                        <li className="sub-category">
                            <h3>اصلی</h3>
                        </li>
                        <li className="slide">
                            <NavLink to="/" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-home"></i><span className="side-menu__label">داشبورد</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/payments" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-dollar-sign"></i><span className="side-menu__label">پرداخت ها</span></NavLink>
                        </li>
                        <li className="sub-category">
                            <h3>مدیریت</h3>
                        </li>
                        <li className="slide">
                            <NavLink to="/game-types" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-database"></i><span className="side-menu__label">سبک بازی ها</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/roles" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-box"></i><span className="side-menu__label">نقش ها</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/last-move-cards" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-credit-card"></i><span className="side-menu__label">کارت های حرکت آخر</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/sliders" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-image"></i><span className="side-menu__label">اسلایدر ها</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/pages" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-file"></i><span className="side-menu__label">صفحات</span></NavLink>
                        </li>
                        <li className="slide">
                            <NavLink to="/users" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-user"></i><span className="side-menu__label">کاربران</span></NavLink>
                        </li>
                        <li className="slide">
                            <a href="https://mafiabaza.com" target="_blank" className="side-menu__item has-link" data-bs-toggle="slide"><i className="side-menu__icon fe fe-eye"></i><span className="side-menu__label">مشاهده سایت</span></a>
                        </li>
                    </ul>
                    <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"/></svg></div>
                </div>
            </div>
        </div>
    )
}
export default Sidebar