import { useState,useEffect,useRef } from 'react'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import LoadingBar from 'react-top-loading-bar'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Select from '../../components/Select'

const Create = () => {

    const navigate = useNavigate()
    const loading = useRef(null)
    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {
        axios.post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
            else
                data.error.map(error => toast.error(error))
        })
    },[])

    const initialValues = {
        lastMoveCards: [
            {
                title: '',
                description: '',
                gameType: ''
            }
        ],
        gameType: ''
    }

    const validationSchema = Yup.object({
        lastMoveCards: Yup.array().of(
            Yup.object().shape(({
                title: Yup.string().required().max(64),
                description: Yup.string().required()
            }))
        ),
        gameType: Yup.string().required()
    })

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading')
        loading.current.continuousStart()
        const postedData = {
            ...values
        }
        axios.post('last-move-cards/create/bulk',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/last-move-cards')
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete()
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        })
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const addRow = () => {
        formik.setFieldValue('lastMoveCards',[...formik.values.lastMoveCards,{
            title: '',
            description: ''
        }])
    }

    const removeRow = index => {
        const oldRoles = [...formik.values.lastMoveCards]
        oldRoles.splice(index,1)
        formik.setFieldValue('lastMoveCards',oldRoles)
    }

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">ایجاد کارت حرکت آخر جدید</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Select label="سبک بازی" name="gameType" formik={formik} required={true}>
                                            {gameTypes.map(gameType => <option key={gameType._id} value={gameType._id}>{gameType.title}</option>)}
                                        </Select>
                                    </div>
                                </div>
                                {formik.values.lastMoveCards.map((card,index) => <div key={index} className="row">
                                    <div className="col-md-3">
                                        <Input label="عنوان" name={`lastMoveCards[${index}].title`} formik={formik} required={true} maxLength={64} />
                                    </div>
                                    <div className="col-md-8">
                                        <Input label="توضیحات" name={`lastMoveCards[${index}].description`} formik={formik} required={true} />
                                    </div>
                                    <div className="col-md-1">
                                        {index == 0 ? <button className="btn btn-info btn-icon" type="button" onClick={addRow} style={{marginTop: 29}}><i className="fe fe-plus"></i></button> : <button className="btn btn-danger btn-icon" type="button" onClick={() => removeRow(index)} style={{marginTop: 29}}><i className="fe fe-minus"></i></button>}
                                    </div>
                                </div>)}
                                <button className="btn btn-success">ذخیره اطلاعات</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create