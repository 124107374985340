import { useEffect,useState } from 'react';
import axios from '../../utils/axios';
import 'react-tagsinput/react-tagsinput.css'
import Input from '../../components/Input';
import InputTag from '../../components/InputTag';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
// import { Editor } from '@tinymce/tinymce-react';

const Form = ({ formik }) => {

    const [categories,setCategories] = useState([]);

    useEffect(() => {
        axios.post('categories',{socialId: formik.values.socialId}).then(({data}) => {
            if(data.status){
                let generateCategories = [];
                data.result.map(item => generateCategories.push({
                    label: item.title,
                    value: item.id
                }))
                setCategories(generateCategories);
            }else
                data.error.map(error => toast.error(error))
        });
    },[])

    useEffect(() => {
        formik.setFieldValue('slug',formik.values.title.toString().replace(/ /g,'-'));
    },[formik.values.title])

    return (
        <form className="col-12" onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-body">
                            <Input label="عنوان" name="title" formik={formik} required={true} maxLength={128} />
                            <Input label="نامک" name="slug" formik={formik} required={true} maxLength={250} />
                            {/* <Editor
                                onChange={e => formik.setFieldValue('content',e.level.content)}
                                initialValue={formik.values.content}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help'
                                }}
                            /> */}
                            <div className="mt-3">
                                <InputTag label="برچسب ها" name="tags" formik={formik} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            {/* <Select label="دارای محصول" name="hasProducts" formik={formik} required={true}>
                                <option value="1">بله</option>
                                <option value="0">خیر</option>
                            </Select> */}
                            {formik.values.hasProduct && false ? <ReactSelect label="دسته بندی" isMulti={true} name="categoriesId" formik={formik} required={true} options={categories} /> : ''}
                            <button className="btn btn-success btn-block">ذخیره اطلاعات</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Form;