import Input from '../../components/Input'
import InputFile from '../../components/InputFile'

const Form = ({ formik }) => {

    const toBase64 = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handleFileInput = async e => {
        const file = e.target.files[0]
        const finalFile = await toBase64(file)
        formik.setFieldValue('image',finalFile)
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-3">
                    <Input label="عنوان" name="title" formik={formik} required={true} maxLength={64} />
                </div>
                <div className="col-md-2">
                    <Input label="حداقل تعداد" type="number" min="0" name="min" formik={formik} required={true} />
                </div>
                <div className="col-md-2">
                    <Input label="حداکثر تعداد" type="number" min="0" name="max" formik={formik} required={true} />
                </div>
                <div className="col-md-5">
                    {formik.values.image && <a href={`https://mafiabaza.com${formik.values.image}`} target="_blank" className="show-old-image-link">مشاهده تصویر</a>}
                    <InputFile label="تصویر" name="image" onChange={handleFileInput} />
                </div>
            </div>
            <button className="btn btn-success">ذخیره اطلاعات</button>
        </form>
    )
}

export default Form