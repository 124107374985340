import { useState,useEffect, useRef } from 'react'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import LoadingBar from 'react-top-loading-bar'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import InputFile from '../../components/InputFile'
import Select from '../../components/Select'

const Update = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const loading = useRef(null)

    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {   
        axios.post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
        })
    },[])

    const initialValues = {
        title: '',
        description: '',
        sort: '',
        image: '',
        side: '',
        gameType: '',
        countable: 0,
        defaultChecked: 0
    }

    useEffect(() => {
        axios.post('roles',{id}).then(({data}) => {
            if(data.status)
                formik.setValues({
                    ...data.result,
                    gameType: data.result.gameType._id,
                    countable: data.result.countable ? 1 : 0,
                    defaultChecked: data.result.defaultChecked ? 1 : 0
                })
            else
                data.error.map(error => toast.error(error))
        })
    },[])

    const validationSchema = Yup.object({
        title: Yup.string().required(),
        description: Yup.string().required(),
        sort: Yup.number().nullable(),
        side: Yup.string().required(),
        gameType: Yup.string().required()
    })

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading')
        loading.current.continuousStart()
        const postedData = {
            id,
            ...values,
            countable: values.countable == 1 ? true : false,
            defaultChecked: values.defaultChecked == 1 ? true : false
        }
        axios.post('roles/update',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/roles')
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete()
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        })
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const toBase64 = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handleFileInput = async e => {
        const file = e.target.files[0]
        const finalFile = await toBase64(file)
        formik.setFieldValue('image',finalFile)
    }

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">به روزرسانی نقش {formik.values.title}</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Select label="سبک بازی" name="gameType" formik={formik} required={true}>
                                            {gameTypes.map(gameType => <option key={gameType._id} value={gameType._id}>{gameType.title}</option>)}
                                        </Select>
                                    </div>
                                    <div className="col-md-2">
                                        <Select label="ساید" name="side" formik={formik} required={true}>
                                            <option value="citizen">شهروند</option>
                                            <option value="mafia">مافیا</option>
                                            <option value="independent">مستقل</option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Input label="عنوان" name={'title'} formik={formik} required={true} maxLength={64} />
                                    </div>
                                    <div className="col-md-4">
                                        <Input label="توضیحات" name={'description'} formik={formik} required={true} />
                                    </div>
                                    <div className="col-md-2">
                                        <Input label="ترتیب" name={'sort'} type="number" min="0" formik={formik} required={true} />
                                    </div>
                                    <div className="col-md-2">
                                        <Select label="تعداد متغیر" name={'countable'} formik={formik} required={true}>
                                            <option value="0">خیر</option>
                                            <option value="1">بله</option>
                                        </Select>
                                    </div>
                                    <div className="col-md-2">
                                        <Select label="پیشفرض" name={'defaultChecked'} formik={formik} required={true}>
                                            <option value="0">خیر</option>
                                            <option value="1">بله</option>
                                        </Select>
                                    </div>
                                    {false && <div className="col-md-2">
                                        {formik.values.image && <a href={`https://mafiabaza.com${formik.values.image}`} target="_blank" className="show-old-image-link">مشاهده تصویر</a>}
                                        <InputFile label="تصویر" name={'image'} onChange={handleFileInput} />
                                    </div>}
                                </div>
                                <button className="btn btn-success">ذخیره اطلاعات</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update