import axios from 'axios'
import { useContext,useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Context } from '../context/Context'

const Login = () => {

    document.body.classList.add('login-img')

    const [phone,setPhone] = useState('')
    const [sendOtp,setSendOtp] = useState(false)
    const [code,setCode] = useState('')

    const context = useContext(Context)

    const navigate = useNavigate()

    useEffect(() => {
        if(sendOtp)
            document.getElementById('verify-code').focus()
    },[sendOtp])

    const login = () => {
        document.getElementById('btn-submit-form').classList.add('btn-loading')
        const postedData = {
            phone
        }
        axios.post('auth/login',postedData).then(({data}) => {
            if(data.status){
                setSendOtp(true)
                toast.success(data.message)
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => document.getElementById('btn-submit-form').classList.remove('btn-loading'))
    }

    const confirmLogin = () => {
        document.getElementById('btn-submit-form').classList.add('btn-loading')
        const postedData = {
            phone,
            otp: code
        }
        axios.post('auth/verify',postedData).then(({data}) => {
            if(data.status){
                context.setUser(data.result)
                toast.success('ورود با موفقیت انجام شد')
                localStorage.setItem('token',data.result.token)
                setTimeout(() => {
                    navigate('/')
                },1000)
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => document.getElementById('btn-submit-form').classList.remove('btn-loading'))
    }

    const handleForm = e => {
        e.preventDefault()
        if(sendOtp)
            confirmLogin()
        else
            login()
    }

    return (
        <div className="page">
            <div className="">
                <div className="container-login100">
                    <div className="wrap-login100 p-6">
                        <div className="text-center mb-4">
                            <img src="/assets/images/brand/logo-white.png" className="header-brand-img" style={{width: 200}} alt="" />
                        </div>
                        <hr />
                        <form className="login100-form validate-form" onSubmit={e => handleForm(e)}>
                            <span className="login100-form-title pb-1">ورود</span>
                            <div className="panel panel-primary">
                                <div className="panel-body tabs-menu-body p-0 pt-5">
                                    <div className="wrap-input100 validate-input input-group">
                                        <a className="input-group-text bg-white text-muted">
                                            <i className="zmdi zmdi-phone text-muted" aria-hidden="true"></i>
                                        </a>
                                        <input className="input100 border-start-0 form-control ms-0 ltr-input" maxLength="11" type="tel" placeholder="شماره همراه" value={phone} onChange={e => setPhone(e.target.value)} disabled={sendOtp} />
                                    </div>
                                    {sendOtp ? <div className="wrap-input100 validate-input input-group">
                                        <a className="input-group-text bg-white text-muted">
                                            <i className="zmdi zmdi-lock text-muted" aria-hidden="true"></i>
                                        </a>
                                        <input className="input100 border-start-0 form-control ms-0 ltr-input text-center" id="verify-code" maxLength="5" type="tel" placeholder="کد اعتبارسنجی" value={code} onChange={e => setCode(e.target.value)} />
                                    </div> : ''}
                                    <div className="container-login100-form-btn">
                                        {!sendOtp ? <button className="login100-form-btn btn btn-primary" id="btn-submit-form" disabled={!phone || phone.length != 11}>ارسال کد اعتبارسنجی</button> : <button className="login100-form-btn btn btn-success" id="btn-submit-form" disabled={!code || code.length != 5}>ورود</button>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login