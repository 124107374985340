const Select = ({ children,label,name,formik,disabled = false,required = false }) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <select name={name} required={required} className={`form-control ${formik.errors[name] ? ' is-invalid' : ''}`} {...formik?.getFieldProps(name)} disabled={disabled}>
                <option value="">انتخاب کنید</option>
                {children}
            </select>
            {formik.errors[name] && formik.touched[name] && (
                <small className="text-danger">{formik.errors[name]}</small>
            )}
        </div>
    )
}

export default Select