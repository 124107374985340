import { useState,useEffect, useRef } from 'react'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import LoadingBar from 'react-top-loading-bar'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import InputFile from '../../components/InputFile'
import Select from '../../components/Select'

const Create = () => {

    const navigate = useNavigate()
    const loading = useRef(null)

    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {   
        axios.post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
        })
    },[])

    const initialValues = {
        roles: [
            {
                title: '',
                description: '',
                sort: '1',
                image: '',
                countable: 0,
                defaultChecked: 0
            }
        ],
        side: '',
        gameType: ''
    }

    const validationSchema = Yup.object({
        roles: Yup.array().of(
            Yup.object().shape(({
                title: Yup.string().required(),
                description: Yup.string().required(),
                sort: Yup.number().nullable()
            }))
        ),
        side: Yup.string().required(),
        gameType: Yup.string().required()
    })

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading')
        loading.current.continuousStart()
        const roles = []
        values.roles.map(role => roles.push({
            ...role,
            countable: role.countable == 1 ? true : false,
            defaultChecked: role.defaultChecked == 1 ? true : false
        }))
        const postedData = {
            roles,
            side: values.side,
            gameType: values.gameType
        }
        axios.post('roles/create/bulk',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/roles')
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete()
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        })
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const toBase64 = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handleFileInput = async e => {
        const file = e.target.files[0]
        const finalFile = await toBase64(file)
        formik.setFieldValue('image',finalFile)
    }

    const addRow = () => {
        formik.setFieldValue('roles',[...formik.values.roles,{
            title: '',
            description: '',
            sort: (parseInt(formik.values.roles[formik.values.roles.length - 1].sort) + 1).toString(),
            image: '',
            countable: 0
        }])
    }

    const removeRow = index => {
        const oldRoles = [...formik.values.roles]
        oldRoles.splice(index,1)
        formik.setFieldValue('roles',oldRoles)
    }

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">ایجاد نقش جدید</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Select label="سبک بازی" name="gameType" formik={formik} required={true}>
                                            {gameTypes.map(gameType => <option key={gameType._id} value={gameType._id}>{gameType.title}</option>)}
                                        </Select>
                                    </div>
                                    <div className="col-md-2">
                                        <Select label="ساید" name="side" formik={formik} required={true}>
                                            <option value="citizen">شهروند</option>
                                            <option value="mafia">مافیا</option>
                                            <option value="independent">مستقل</option>
                                        </Select>
                                    </div>
                                </div>
                                {formik.values.roles.map((role,index) => <div key={index} className="row">
                                    <div className="col-md-11">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Input label="عنوان" name={`roles[${index}].title`} formik={formik} required={true} maxLength={64} />
                                            </div>
                                            <div className="col-md-4">
                                                <Input label="توضیحات" name={`roles[${index}].description`} formik={formik} required={true} />
                                            </div>
                                            <div className="col-md-2">
                                                <Input label="ترتیب" name={`roles[${index}].sort`} type="number" min="0" formik={formik} required={true} />
                                            </div>
                                            <div className="col-md-2">
                                                <Select label="تعداد متغیر" name={`roles[${index}].countable`} formik={formik} required={true}>
                                                    <option value="0">خیر</option>
                                                    <option value="1">بله</option>
                                                </Select>
                                            </div>
                                            <div className="col-md-2">
                                                <Select label="پیشفرض" name={`roles[${index}].defaultChecked`} formik={formik} required={true}>
                                                    <option value="0">خیر</option>
                                                    <option value="1">بله</option>
                                                </Select>
                                            </div>
                                            {false && <div className="col-md-2">
                                                {formik.values.image && <a href={`https://mafiabaza.com${formik.values.image}`} target="_blank" className="show-old-image-link">مشاهده تصویر</a>}
                                                <InputFile label="تصویر" name={`roles[${index}].image`} onChange={handleFileInput} />
                                            </div> }
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        {index == 0 ? <button className="btn btn-info btn-icon" type="button" onClick={addRow} style={{marginTop: 29}}><i className="fe fe-plus"></i></button> : <button className="btn btn-danger btn-icon" type="button" onClick={() => removeRow(index)} style={{marginTop: 29}}><i className="fe fe-minus"></i></button>}
                                    </div>
                                </div>)}
                                <button className="btn btn-success">ذخیره اطلاعات</button>
                            </form>       
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create