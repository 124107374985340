import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Yup from 'yup';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import App from './App';

Yup.setLocale({
  mixed: {
    required: 'وارد کردن این فیلد اجباری است',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  </ContextProvider>
);