const Index = () => {
    return (
        <>
            <div className="page-header">
                <h1 className="page-title">داشبورد</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card p-4">
                        گزارشات پنل در این قسمت قرار می گیرند
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index