import { useEffect, useRef } from 'react';
import Form from './Form';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import LoadingBar from 'react-top-loading-bar';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Update = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const loading = useRef(null);

    const initialValues = {
        title: '',
        slug: '',
        content: '',
        tags: [],
        hasProduct: '',
        categoriesId: []
    };

    useEffect(() => {
        axios.get(`pages/${id}`).then(({data}) => {
            if(data.status)
                formik.setValues({
                    ...data.result,
                    tags: data.result.tags.split(',')
                });
            else
                data.error.map(error => toast.error(error))
        });
    },[])

    const validationSchema = Yup.object({
        title: Yup.string().required().max(128),
        slug: Yup.string().required(),
        content: Yup.string().required(),
        tags: Yup.array().required()
    });

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading');
        loading.current.continuousStart();
        const postedData = {
            id,
            ...values,
            tags: values.tags.join(',')
        };
        axios.put('pages/update',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message);
                navigate('/pages');
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete();
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        });
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">به روزرسانی صفحه {formik.values.title}</h1>
            </div>
            <div className="row">
                <Form formik={formik} />
            </div>
        </>
    )
}

export default Update;