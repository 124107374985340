import { useRef } from 'react';
import Form from './Form';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import LoadingBar from 'react-top-loading-bar';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Create = () => {

    const navigate = useNavigate();
    const loading = useRef(null);

    const initialValues = {
        name: '',
        phone: '',
        status: 1,
        role: 2
    };

    const validationSchema = Yup.object({
        name: Yup.string().required().max(32),
        phone: Yup.string()
        .test('phone','شماره همراه وارد شده معتبر نمی باشد',value => {
            return value && /^\d+$/.test(value) && value.startsWith('09') && value.length == 11;
        }),
        role: Yup.number().required(),
        status: Yup.number().required()
    });

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading');
        loading.current.continuousStart();
        const postedData = {
            ...values,
            status: values.status == 1,
            adjective: 3
        };
        axios.post('users/create',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message);
                navigate('/users');
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete();
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        });
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">ایجاد کاربر جدید</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <Form formik={formik} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create;