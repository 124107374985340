import { createContext,useState } from 'react';

const Context = createContext({
    user: {},
    setUser: () => {}
});

const ContextProvider = ({ children }) => {
    const [user,setUser] = useState({});

    return (
        <Context.Provider value={{user,setUser}}>{children}</Context.Provider>
    )
}
const ContextConsumer = ({ children }) => (
    <Context.Consumer>{children}</Context.Consumer>
)

export {
    Context,
    ContextProvider,
    ContextConsumer
}