import Input from '../../components/Input'
import InputFile from '../../components/InputFile'
import Select from '../../components/Select'

const Form = ({ formik }) => {

    const toBase64 = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handleFileInput = async e => {
        const file = e.target.files[0]
        const finalFile = await toBase64(file)
        formik.setFieldValue('image',finalFile)
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-3">
                    <Input label="عنوان" name="title" formik={formik} required={true} maxLength={64} />
                </div>
                <div className="col-md-3">
                    <Input label="لینک" isLtr={true} name="link" formik={formik} required={true} />
                </div>
                <div className="col-md-4">
                    <InputFile label="تصویر" name="image" onChange={handleFileInput} />
                </div>
                <div className="col-md-2">
                    <Select label="جایگاه" name="position" formik={formik} required={true}>
                        <option value="site">سایت</option>
                        <option value="app">اپلیکیشن</option>
                    </Select>
                </div>
            </div>
            <button className="btn btn-success">ذخیره اطلاعات</button>
        </form>
    )
}

export default Form