import Input from '../../components/Input'
import Select from '../../components/Select'

const Form = ({ formik }) => {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-3">
                    <Input label="نام" name="name" formik={formik} required={true} maxLength={32} />
                </div>
                <div className="col-md-3">
                    <Input label="شماره همراه" type="tel" isLtr={true} name="phone" formik={formik} required={true} maxLength={11} />
                </div>
                <div className="col-md-3">
                    <Select label="وضعیت" name="status" formik={formik} required={true}>
                        <option value="1">فعال</option>
                        <option value="0">غیرفعال</option>
                    </Select>
                </div>
                <div className="col-md-3">
                    <Select label="نقش" name="role" formik={formik} required={true}>
                        <option value="1">مدیریت</option>
                        <option value="2">گرداننده</option>
                    </Select>
                </div>
            </div>
            <button className="btn btn-success">ذخیره اطلاعات</button>
        </form>
    )
}

export default Form