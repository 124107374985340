import { useState,useEffect, useRef } from 'react'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import LoadingBar from 'react-top-loading-bar'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Select from '../../components/Select'

const Update = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const loading = useRef(null)
    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {
        axios.post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
            else
                data.error.map(error => toast.error(error))
        })
    },[])

    const initialValues = {
        title: '',
        description: '',
        gameType: ''
    }

    useEffect(() => {
        axios.post('last-move-cards',{id}).then(({data}) => {
            if(data.status)
                formik.setValues({
                    ...data.result,
                    gameType: data.result.gameType._id
                })
            else
                data.error.map(error => toast.error(error))
        })
    },[])

    const validationSchema = Yup.object({
        title: Yup.string().required().max(64),
        gameType: Yup.string().required(),
        description: Yup.string().required()
    })

    const onSubmit = values => {
        document.getElementsByClassName('btn-success')[0].classList.add('btn-loading')
        loading.current.continuousStart()
        const postedData = {
            id,
            ...values
        }
        axios.post('last-move-cards/update',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/last-move-cards')
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            loading.current.complete()
            document.getElementsByClassName('btn-success')[0].classList.remove('btn-loading')
        })
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">به روزرسانی کارت {formik.values.title}</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Select label="سبک بازی" name="gameType" formik={formik} required={true}>
                                            {gameTypes.map(gameType => <option key={gameType._id} value={gameType._id}>{gameType.title}</option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <Input label="عنوان" name="title" formik={formik} required={true} maxLength={64} />
                                    </div>
                                    <div className="col-md-9">
                                        <Input label="توضیحات" name="description" formik={formik} required={true} />
                                    </div>
                                </div>
                                <button className="btn btn-success">ذخیره اطلاعات</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update