const InputFile = ({ label,name,disabled = false,required = false,readOnly = false,isLtr = false,onChange = () => {} }) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <input
                type="file"
                name={name}
                className={`form-control ${isLtr ? 'ltr-input' : ''} ${required ? ' is-invalid' : ''}`}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    )
}

export default InputFile