import { Route,Routes,useNavigate,useLocation } from 'react-router-dom'
import { history } from './utils/history'

// Layouts
import PanelLayout from './layouts/PanelLayout'

// Home
import Index from './pages/Index'
// GameTypes
import GameTypesIndex from './pages/GameTypes/Index';
import GameTypesCreate from './pages/GameTypes/Create';
import GameTypesUpdate from './pages/GameTypes/Update';
// Last Move Cards
import LastMoveCardsIndex from './pages/LastMoveCards/Index';
import LastMoveCardsCreate from './pages/LastMoveCards/Create';
import LastMoveCardsUpdate from './pages/LastMoveCards/Update';
// Roles
import RolesIndex from './pages/Roles/Index';
import RolesCreate from './pages/Roles/Create';
import RolesUpdate from './pages/Roles/Update';
// Pages
import PagesIndex from './pages/Pages/Index';
import PagesCreate from './pages/Pages/Create';
import PagesUpdate from './pages/Pages/Update';
// Sliders
import SlidersIndex from './pages/Sliders/Index'
import SlidersCreate from './pages/Sliders/Create'
import SlidersUpdate from './pages/Sliders/Update'
// Users
import UsersIndex from './pages/Users/Index'
import UsersCreate from './pages/Users/Create'
import UsersUpdate from './pages/Users/Update'
// Login
import Login from './pages/Login'

const App = () => {

    history.navigate = useNavigate()
    history.location = useLocation()

    return (
        <Routes>
            <Route element={<PanelLayout />}>
                <Route path="/" element={<Index />} />
                <Route path="/game-types" element={<GameTypesIndex />} />
                <Route path="/game-types/create" element={<GameTypesCreate />} />
                <Route path="/game-types/update/:id" element={<GameTypesUpdate />} />
                <Route path="/last-move-cards" element={<LastMoveCardsIndex />} />
                <Route path="/last-move-cards/create" element={<LastMoveCardsCreate />} />
                <Route path="/last-move-cards/update/:id" element={<LastMoveCardsUpdate />} />
                <Route path="/roles" element={<RolesIndex />} />
                <Route path="/roles/create" element={<RolesCreate />} />
                <Route path="/roles/update/:id" element={<RolesUpdate />} />
                <Route path="/pages" element={<PagesIndex />} />
                <Route path="/pages/create" element={<PagesCreate />} />
                <Route path="/pages/update/:id" element={<PagesUpdate />} />
                <Route path="/sliders" element={<SlidersIndex />} />
                <Route path="/sliders/create" element={<SlidersCreate />} />
                <Route path="/sliders/update/:id" element={<SlidersUpdate />} />
                <Route path="/users" element={<UsersIndex />} />
                <Route path="/users/create" element={<UsersCreate />} />
                <Route path="/users/update/:id" element={<UsersUpdate />} />
            </Route>
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}

export default App