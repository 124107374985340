const Pagination = ({ pages,currentPage,prevPage,changePage,nextPage }) => {
    return (
        pages.length > 1 && (
            <ul className="pagination mt-4">
            {currentPage > 1 && (
                <li
                onClick={(e) => {
                    prevPage(e)
                }}
                className="page-item"
                >
                <a className="page-link" href="#">
                    <i className="icon icon-arrow-right"></i>
                </a>
                </li>
            )}
            {pages
                .filter((page) => Math.abs(page - currentPage) < 3)
                .map((page, index) => (
                <li
                    key={index}
                    onClick={(e) => changePage(e, page)}
                    className={"page-item" + (page == currentPage ? " active" : "")}
                >
                    <a className="page-link" href="#">
                    {page}
                    </a>
                </li>
                ))}
            {pages.length > currentPage + 3 && (
                <>
                <li className={"page-item"}>
                    <a className="page-link">
                    ...
                    </a>
                </li>
                <li
                    onClick={(e) => changePage(e, pages.length)}
                    className={"page-item"}
                >
                    <a className="page-link" href="#">
                    {pages.length}
                    </a>
                </li>
                </>
            )}
            {pages.length != currentPage && (
                <li
                onClick={(e) => {
                    nextPage(e)
                }}
                className="page-item"
                >
                <a className="page-link" href="#">
                    <i className="icon icon-arrow-left"></i>
                </a>
                </li>
            )}
            </ul>
        )
    )
  }
  
  export default Pagination