import axios from 'axios'
import { history } from './history'

const url = 'https://mafiabaza.com/api/v1/admin/'

axios.defaults.baseURL = url

axios.interceptors.request.use(config => {
    if(localStorage.getItem('token'))
        config.headers.Authorization = localStorage.getItem('token')
    return config
})

axios.interceptors.response.use(response => response,(error) => {
    if(error.response.status === 401){
        localStorage.removeItem('token')
        history.navigate('/login')
    }
    throw error
})

export default axios