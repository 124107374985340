import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import axios from '../../utils/axios'
import LoadingBar from 'react-top-loading-bar'
import { toast } from 'react-toastify'
import Pagination from '../../components/Pagination'

const Index = () => {

    const [rows,setRows] = useState([])
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const loading = useRef(null)

    const getData = (postedData = {page: 1}) => {
        loading.current.continuousStart()
        axios.post('pages',{...postedData,limit: 10}).then(({data}) => {
            if(data.status){
                calcPages(data.count)
                setRows(data.result)
            }else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            if(loading && loading.current)
                loading.current.complete()
        })
    }
    
    const calcPages = count => {
        if(!count)
            return false
        let calcPages = count / 10
        calcPages = calcPages > 1 ? Math.ceil(calcPages) : 1
        let pagesArray = []
        for (let i = 1;i <= calcPages;i++)
            pagesArray.push(i)
        setPages(pagesArray)
    }

    const changePage = (e, page) => {
        e.preventDefault()
        setCurrentPage(page)
    }

    const prevPage = e => {
        e.preventDefault()
        setCurrentPage(currentPage - 1)
    }

    const nextPage = e => {
        e.preventDefault()
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getData({page: currentPage})
    },[currentPage])

    const deleteItem = id => {
        axios.post('pages/delete',{id}).then(({data}) => {
            if(data.status)
                toast.success(data.message)
            else
                data.error.map(error => toast.error(error))
        }).finally(() => {
            getData()
        })
    }

    return (
        <>
            <LoadingBar color="#4088c7" ref={loading} height={3} shadow={0} />
            <div className="page-header">
                <h1 className="page-title">صفحات</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <Link to="/pages" className="btn btn-success">ایجاد صفحه</Link>
                            <hr />
                            {rows.length ? <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>عنوان</th>
                                            <th>بازدید</th>
                                            <th>کاربر</th>
                                            <th>تاریخ ایجاد</th>
                                            <th>تاریخ به روزرسانی</th>
                                            <th>عملیات</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map(row => (
                                            <tr key={row._id}>
                                                <td>{row.title}</td>
                                                <td>{row.visits}</td>
                                                <td>{row.user ? row.user.name : '-'}</td>
                                                <td>{new Date(row.createdAt).toLocaleDateString('fa-IR')}</td>
                                                <td>{new Date(row.updatedAt).toLocaleDateString('fa-IR')}</td>
                                                <td className="btn-list">
                                                    <Link to={'update/' + row._id} className="btn btn-icon mb-0 btn-primary"><i className="fe fe-edit-2"></i></Link>
                                                    <button onClick={() => deleteItem(row._id)} className="btn btn-icon mb-0 btn-danger"><i className="fe fe-x"></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination pages={pages} currentPage={currentPage} prevPage={prevPage} nextPage={nextPage} changePage={changePage} />
                            </div> : <div className="alert alert-info"><p className="mb-0">اطلاعاتی یافت نشد</p></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index