const Input = ({ label,name,formik,disabled = false,type = 'text',required = false,readOnly = false,isLtr = false,maxLength = '',min = '',max = '' }) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <input
                type={type}
                name={name}
                className={`form-control ${isLtr ? 'ltr-input' : ''} ${formik.errors[name] ? ' is-invalid' : ''}`}
                required={required}
                readOnly={readOnly}
                maxLength={maxLength}
                disabled={disabled}
                max={max}
                min={min}
                {...formik.getFieldProps(name)}
            />
            {formik.errors[name] && formik.touched[name] && (
                <small className="text-danger">{formik.errors[name]}</small>
            )}
        </div>
    )
}

export default Input