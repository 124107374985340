import TagsInput from 'react-tagsinput'

const InputTag = ({ label,name,formik,disabled = false,isLtr = false }) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            <TagsInput
                className={`form-control custom-form-tags-control ${isLtr ? 'ltr-input' : ''} ${formik.errors[name] ? ' is-invalid' : ''}`}
                value={formik.values[name]}
                onChange={tags => formik.setFieldValue(name,tags)}
                disabled={disabled}
                inputProps={{placeholder: ''}}
            />
            {formik.errors[name] && formik.touched[name] && (
                <small className="text-danger">{formik.errors[name]}</small>
            )}
        </div>
    )
}

export default InputTag